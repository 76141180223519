<template>
     <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>bar_chart</md-icon>
                </div>
                <h4 class="title">Quest</h4>
                </md-card-header>
                <md-card-content>
                    <md-table v-model="logList" class="link-table" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="No">{{ (page-1)*10+(item.index+1) }}</md-table-cell>
                            <md-table-cell md-label="Nickname">{{item.nickname}}</md-table-cell>
                            <md-table-cell md-label="Date">{{ dateFormat(item.updatedAt) }}</md-table-cell>
                            <md-table-cell md-label="Time">{{ timeFormat(item.updatedAt) }}</md-table-cell>
                            
                            <md-table-cell md-label="Quest 1">
                                <div v-if="!item.stage.step1" class="custom-checkBox"></div>
                                <div v-else class="custom-checkBox-active"></div>
                            </md-table-cell>
                            <md-table-cell md-label="Quest 2">
                                <div v-if="!item.stage.step2" class="custom-checkBox"></div>
                                <div v-else class="custom-checkBox-active"></div>
                            </md-table-cell>
                            <md-table-cell md-label="Quest 3">
                                <div v-if="!item.stage.step3" class="custom-checkBox"></div>
                                <div v-else class="custom-checkBox-active"></div>
                            </md-table-cell>
                            <md-table-cell md-label="SNS">
                                <div v-if="item.metaData && item.metaData.shareSNS" class="custom-checkBox-active"></div>
                                <div v-else class="custom-checkBox"></div>
                            </md-table-cell>

                            <md-table-cell md-label="Quest Card">
                                {{getCardNum(item.symbol)}}
                                <!-- <img v-if="item.symbol" class="item-thumb" :src="item.symbol" alt="..." /> -->
                            </md-table-cell>
                            <md-table-cell md-label="Delete">
                                <a href="javascript:void(0);" @click="deleteLog(item._id)" class="delBtn">delete</a>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <div class="md-layout md-alignment-center-center">
                        <pagination  :records="totalDocs" v-model="page" :per-page="limit" @paginate="questLogHandler"></pagination>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
import Pagination from 'vue-pagination-2';

export default {
    components: {
        Pagination
    },
    data() {
        return {
            logList: [],
            page: 1,
            limit: 10,
            totalPages: 0,
            totalDocs: 0
        }
    },
    async created() {
        const res = await axios.get(api.GET_QUESTLOG(this.page, this.limit));
        if(!res.data.success) {
            return;
        }

        this.logList = res.data.data.docs;
        this.totalPages = res.data.data.totalPages;
        this.totalDocs = res.data.data.totalDocs;
        
        this.logList.forEach((element, index) => {
            element.index = index;
        });
    },
    methods: {
        async deleteLog(questLogId) {
            const result = confirm("퀘스트 로그를 삭제하시겠습니까?");
            if(!result) return;

            const res = await axios.delete(api.DELETE_QUESTLOG(questLogId),
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );
            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "삭제 실패");

                return;
            }

            const delIndex = this.logList.findIndex(item => {
                return item._id === questLogId;
            });
            this.logList.splice(delIndex, 1);

            this.$store.dispatch("alerts/success", "삭제 성공");
        },
        dateFormat(date) {
            const target = new Date(date);
            
            const year = target.getFullYear();
            const month = ('0' + (target.getMonth() + 1)).slice(-2);
            const day = ('0' + target.getDate()).slice(-2);
            const hours = ('0' + target.getHours()).slice(-2); 
            const minutes = ('0' + target.getMinutes()).slice(-2);

            return year + '-' + month  + '-' + day;
        },
        timeFormat(date) {
            const target = new Date(date);

            const hours = ('0' + target.getHours()).slice(-2); 
            const minutes = ('0' + target.getMinutes()).slice(-2);

            return hours + ':' + minutes;
        },
        async questLogHandler(page) {
            const res = await axios.get(api.GET_QUESTLOG(page, this.limit));
            if(!res.data.success) {
                return;
            }

            this.logList = res.data.data.docs;
            this.totalPages = res.data.data.totalPages;
            this.totalDocs = res.data.data.totalDocs;

            this.logList.forEach((element, index) => {
                element.index = index;
            });
        },
        getCardNum(link) {
            if(!link) return "-";
            
            const num = link.replace("https://kf-static.s3.ap-northeast-2.amazonaws.com/assets/quest_symbol/symbol-", "").replace(".png", "");

            return `No.${num}`;
        }
    }
}
</script>

<style scoped>
.item-thumb {
    max-width: 50px;
}
.delBtn {
    color: red !important;
}
.custom-checkBox {
    width: 15px;
    height: 15px;
    border: solid 1px black;
}
.custom-checkBox-active {
    width: 15px;
    height: 15px;
    border: solid 1px #9c27b0;
    background-color: #9c27b0;
}
</style>
<style>
.VuePagination__count {
    display: none !important;
}
</style>